import React from "react"

export default function header() {
  return (
    <div className="container">
      <h1 className="mt-5 display-4 text-center" style={{ color: "#FF8A00" }}>
        <span style = {{color : "white"}}>Gengi</span>&nbsp;gjaldmiðla
      </h1>
    </div>
  )
}
